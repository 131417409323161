import { Box, Paper, PaperProps } from '@mui/material';
import React, { ElementRef } from 'react';

interface OwnProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  borderTop?: boolean;
}

type Props = OwnProps & Omit<PaperProps, 'title'>;

const PagePaper: React.FC<Props> = React.forwardRef<ElementRef<typeof Paper>, Props>(
  ({ title, borderTop = !!title, children, ...rest }, ref) => (
    <Paper
      {...rest}
      sx={{
        p: 2,
        borderTop: borderTop ? (theme) => `2px solid ${theme.palette.primary.main}` : null,
        ...rest.sx,
      }}
      ref={ref}
    >
      {title && (
        <Box fontSize="0.875rem" mb={2} mt={-2} mx={-2} p={2} fontWeight="700" borderBottom="1px solid #e7eaec">
          {title}
        </Box>
      )}
      {children}
    </Paper>
  ),
);

PagePaper.displayName = 'PagePaper';

export default PagePaper;
